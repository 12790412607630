import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from '../../Components/Navigation';
import ButtonNext from '../../Components/ButtonNext';
import PhoneInput from 'react-phone-number-input/input'

const WhoInvitedMe = ( {inviterPhoneNumber, setInviterPhoneNumber, LOCAL_STORAGE_INVITER_PHONE_NUMBER } ) => {
  const [buttonDisabled, setButtonDisable] = useState(true)
  const [timeoutTrigger, setTimeoutTrigger] = useState(false)
  const [submitPressed, setSubmitPressed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [error, setError] = useState(false)
  const navigate = useNavigate();
  const linkBack = ""
  const linkSkip = ""
  const phoneNumberStyle = { //Den här css ska över i css-fil. OBS: Den här behövs för att det inte ska bli lagg i css-laddning
    border: 'none',
    padding: '10px 10px 10px 10px',
    fontSize: '20px',
    fontFamily: 'Montserrat',
    textAlign: 'center',
    borderRadius: '40px',
    color: "#000"
  }
  const checkIfInviterExistsUrl = process.env.REACT_APP_CLOUD_FUNCTION_API_CHECKIFINVITEREXISTS

  //Generate error message when upload takes to long
  useEffect(() => {
    if (!submitPressed) return
    const timer = setTimeout(() => {
      if (error) return
      setErrorMessage("Kontrollera uppkopplingen.")
      setError(true)
    }, 5000);
    return () => {
      clearTimeout(timer);
    }
  }, [submitPressed, error, timeoutTrigger])

  const handleGetFromFirestore = async () => {
    try {
      setIsLoading(true)
      const response = await fetch(checkIfInviterExistsUrl,{
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inviterPhoneNumber: inviterPhoneNumber
        }),
      })
      const inviterExists = await response.json()
      if (inviterExists) {
        setIsLoading(false)
        localStorage.setItem(LOCAL_STORAGE_INVITER_PHONE_NUMBER, JSON.stringify(inviterPhoneNumber))
        navigate("../app/complete-your-invite") //Then navigate to next page
      } else {
        setIsLoading(false)
        setErrorMessage("Inbjudan saknas. Har du skrivit fel nr?")
        setError(true)
      }
    } catch (error) {
      console.log("Felmeddelande:", error)
      setIsLoading(false)
      setErrorMessage("Något gick fel. Försök igen.")
      setError(true)
    }
  }

  const handleSubmit = (event) =>{
    event.preventDefault()
    setError(false)
    //Ensure error message is visible if upload takes to long
    setSubmitPressed(true)
    setTimeoutTrigger(current => !current)
    handleGetFromFirestore()
  }

  useEffect(() => {
    if (inviterPhoneNumber === undefined) {
      setButtonDisable(true)
    } else {
      if (inviterPhoneNumber.length < 12 ) {
        setButtonDisable(true)
      } else {
        setButtonDisable(false)
      }
    }      
  }, [inviterPhoneNumber])

  return (
    <div className='flex flex-1 h-[calc(100dvh)] flex-col items-center justify-center bg-[#000]'>

      <div className='flex flex-1 justify-between flex-col'>
        <Navigation
          linkBack={linkBack}
          linkSkip={linkSkip}
          showLinkBack={false} 
          showLinkSkip={false}
        />
        <div className='mb-10'>
          <h2 className='text-xl text-[#fff]'>
            Hej! Vem har bjudit in dig?
          </h2>
        </div>
      </div>
      
      <div className='flex flex-1 justify-between flex-col'>
        <div className='flex justify-center'>
          <PhoneInput
              country="SE"
              style={phoneNumberStyle}
              placeholder={"07xxxxxxxx"}
              value={inviterPhoneNumber}
              onChange={setInviterPhoneNumber}
            />
        </div>
        <div className='flex flex-1 justify-center items-end mb-10'>
          <ButtonNext
            handleSubmit={handleSubmit}
            buttonDisabled={buttonDisabled}
            isLoading={isLoading}
            error={error}
            errorMessage={errorMessage}
            buttonText={"Bekräfta inbjudan"}
          />
        </div>
      </div>
    </div>
  );
}

export default WhoInvitedMe;
