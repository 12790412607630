import React from 'react';
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";

function ButtonNext({handleSubmit, buttonDisabled, isLoading, buttonText, error, errorMessage}) {
  return (
    <div className='flex w-full flex-col items-center'>
        <div className='mb-2'>
            {error ? (<p className='text-xs text-[#BA891E]'>{errorMessage}</p>) : <p></p>}
        </div>   
        <button
        onClick={handleSubmit}
        disabled={buttonDisabled}
        className="flex items-center justify-center gap-2 py-5 w-full rounded-full text-base text-black bg-[#1db91d] duration-200 hover:bg-[#326532] disabled:bg-[#202020]">
          <div className='flex flex-1'></div>
          {isLoading && <Spinner color="#000" size={10} speed={1} animating={true} />}
          {buttonText}
          <div className='flex flex-1'/>
        </button>
  </div>    
    );
}

export default ButtonNext;
