import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from '../../Components/Navigation';
import ButtonNext from '../../Components/ButtonNext';
import PhoneInput from 'react-phone-number-input/input'
// import { database } from '../../Firebase'
// import { doc, setDoc, serverTimestamp } from 'firebase/firestore'

const PhoneNumberForWaiting = ( {phoneNumber, setPhoneNumber, LOCAL_STORAGE_KEY_PHONE_NUMBER} ) => {
  const [buttonDisabled, setButtonDisable] = useState(true)
  const [timeoutTrigger, setTimeoutTrigger] = useState(false)
  const [submitPressed, setSubmitPressed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [error, setError] = useState(false)
  const navigate = useNavigate();
  const linkBack = ""
  const linkSkip = ""
  const phoneNumberStyle = { //Den här css ska över i css-fil. OBS: Den här behövs för att det inte ska bli lagg i css-laddning
    border: 'none',
    padding: '10px 10px 10px 10px',
    fontSize: '20px',
    fontFamily: 'Montserrat',
    textAlign: 'center',
    borderRadius: '40px',
    color: "#000"
  }
  const signUpWaitingListUrl = process.env.REACT_APP_CLOUD_FUNCTION_API_SIGNUPWAITINGLIST

  //Generate error message when upload takes to long
  useEffect(() => {
    if (!submitPressed) return
    const timer = setTimeout(() => {
      setErrorMessage("Kontrollera uppkopplingen.")
      setError(true)
    }, 5000);
    return () => {
      clearTimeout(timer);
    }
  }, [timeoutTrigger, submitPressed])

  const handleSaveToFirestore = async () => {
    try {
      const response = await fetch(signUpWaitingListUrl,{
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          signUpPhoneNumber: phoneNumber
        }),
      })
      const savedSuccessfully = await response.json()
      if (savedSuccessfully) {
        setIsLoading(false)
        navigate("../confirmed-waiting") //Then navigate to next page
      } else {
        setIsLoading(false)
        setErrorMessage("Något gick fel. Försök igen.")
        setError(true)
      }      
    } catch (e) {
      setIsLoading(false)
      setErrorMessage("Något gick fel. Försök igen.")
      setError(true)
    }
  }

  const handleSubmit = async (event) =>{
    event.preventDefault()
    setError(false)
    setIsLoading(true)
    //Ensure error message is visible if upload takes to long
    setSubmitPressed(true)
    setTimeoutTrigger(current => !current)
    await handleSaveToFirestore()
    localStorage.setItem(LOCAL_STORAGE_KEY_PHONE_NUMBER, JSON.stringify(phoneNumber)) //Save phone number to local storage on submit
  }

  useEffect(() => {
    if (phoneNumber === undefined) {
      setButtonDisable(true)
    } else {
      if (phoneNumber.length < 12 ) {
        setButtonDisable(true)
      } else {
        setButtonDisable(false)
      }
    }      
  }, [phoneNumber])

  return (
    <div className='flex h-screen flex-col bg-[#000]'>
      <div className='flex justify-between flex-col h-5/6 p-5 bg-[#000]'>
        <Navigation
          linkBack={linkBack}
          linkSkip={linkSkip}
          showLinkBack={false} 
          showLinkSkip={false}
        />

        <div className='flex flex-col justify-center'>
          <div className='flex justify-center text-center text-xl text-[#fff]'>
            Väntelista:
          </div>
          <div className='flex justify-center text-center text-xl text-[#fff]'>
            Vad är ditt telefonnummer?
          </div>
        </div>

        <div className='flex justify-center'>
          <PhoneInput
            country="SE"
            style={phoneNumberStyle}
            placeholder={"07xxxxxxxx"}
            value={phoneNumber}
            onChange={setPhoneNumber} />
        </div>
        
        <div className='flex justify-center'>
          <ButtonNext
            handleSubmit={handleSubmit}
            buttonDisabled={buttonDisabled}
            isLoading={isLoading}
            error={error}
            errorMessage={errorMessage}
            buttonText={"Nästa ➔"}
          />
        </div>
      </div>
    </div>
  );
}

export default PhoneNumberForWaiting;
