// import Navigation from '../../Components/Navigation';
import PlayStoreBadge from '../../Images/Ladda_ned_på_Play_Store_badge.png'
import AppStoreBagde from '../../Images/Hämta_i_App_Store_Badge.png'

const ConfirmedInvitation = () => {

  return (
    <div className='flex flex-1 h-[calc(100dvh)] flex-col items-center justify-center bg-[#000]'>
          <div className='mb-5'>
            <h2 className='text-xl text-[#fff] text-center'>
              Nu kan du logga in! 🎉
            </h2>
          </div>
          <div className='w-3/4 mb-5'>
            <p className='text-base text-[#fff] text-center'>
              Ladda ner appen. Klicka här:
              {/* Ladda ner appen för att få direktåtkomst till bostadsmarknaden. */}
            </p>
          </div>
          <div className='flex flex-col w-full items-center'>
            <a href="itms-apps://apps.apple.com/app/id6670727864" className='w-3/4 max-w-[400px]'>
              <img src={AppStoreBagde} alt="AppStoreBadge" className='w-full mb-5'/>
            </a>
            <a href="market://details?id=host.exp.houtive.prod" className='w-3/4 max-w-[400px]'>
              <img src={PlayStoreBadge} alt="PlayStoreBadge"/>
            </a>
          </div>
    </div>
  );
}

export default ConfirmedInvitation;
